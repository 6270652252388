import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const sentieretcheminnotes = () => (
  <Layout>
    <SEO title="Sentier et Chemin" />
    <h3 className='underline-title'>Note</h3>
    <br /><br />
    <div className="table-responsive">
      <p>
        <span className="par-starting"></span>
        Sous le titre <span className="black-bold">Sentier et Chemin</span>, sont regroupés quelques uns de mes poèmes polycopiés
        et ceux déja publiés dans deux plaquettes intitulées <span className="black-bold">Neuf Poètes du Kivu</span> (édité à Kinshasa par le Centre Culturel Français) et <span className="black-bold">Quatres Poètes du Kivu</span> (Centre Africain de Littérature 1974, imprimé également à Kinsha)
      </p>
      <p>
      <span className="par-starting"></span>
      Le souci qui a dicté la réédition de ces poèmes est qu'ils sont introuvables
      et qu'ils sont sans cesse demandés.
      </p>
      <p className="date-published">Bukavu, le 9 novembre 1977</p>
      <p>L'auteur</p>
      <br /><br />
    </div>
    <Link to="/sentier-et-chemin-avant-propos/">Avant-propos</Link>
  </Layout>
)

export default sentieretcheminnotes
